@import"../styles/helpers";
@import"../styles/breakpoints";

.product{

    &__outer-wrapper{
        position: fixed;
        width: 100%;
        height: var(--app-height);
        background-color: var(--grey-trans);
        z-index: 2;
        top: 0;
        left: 0;
        overflow-y: auto;
        cursor: default;
    }

    &__media-wrapper{
        width: calc(80% - 81px);
        margin: 0 20px;
        padding: 100px 0;
        min-height: calc(var(--app-height) - 200px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__media-item-wrapper{
        max-height: calc(var(--app-height) - 240px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 20px;
    }

    &__media-item{
        max-height: calc(var(--app-height) - 200px);
        width: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    &__info-wrapper{
        position: fixed;
        top: 0;
        right: 0;
        margin: 80px 20px 50px 0;
        width: 20%;
        max-height: calc(var(--app-height) - 130px);
        border-left: 0.5px solid #000;
        border-right: 0.5px solid #000;
        padding: 0 10px;
    }

    &__product-header{
        width: 100%;
        padding: 10px 0;
        border-top: 0.5px solid #000;
        border-bottom: 0.5px solid #000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        line-height: 20px;
    }

    &__product-description{
        padding: 20px 10px 10px 0;
        max-height: calc(var(--app-height) - 270px);
        overflow-y: auto;
        border-bottom: 0.5px solid #000;
        p{
            margin-bottom: 10px;
        }
    }

    &__back-button{
        position: fixed;
        top: 0;
        left: 0;
        padding: 5px 10px;
        margin: 15px 20px;
        border: 0.5px solid transparent;
        transition: all 0.2s ease-in-out;
        @include hover{
            border: 0.5px solid #000;
        }
    }

    &__terms{
        margin: 30px 20px 10px 20px;
        text-align: center;
        font-size: 8px;
        line-height: 10px;
        a{
            text-decoration: underline;
        }
    }
}

@include mobile {

    .product{
        &__outer-wrapper{
            width: calc(100% - 40px);
            padding: 100px 20px;
            height: calc(var(--app-height) - 200px);
            display: block;
            overflow-y: auto;
        }
        &__inner-wrapper{
            display: block;
        }
        &__back-button{
            padding: 5px 10px;
            margin: 15px 5px;
        }

        &__info-wrapper{
            position: static;
            margin: 0 0 20px 0;
            width: calc(100% - 22px);
            max-height: none;
        }


        &__media-wrapper{
            width: 100%;
            margin: 0;
            padding: 0;
            min-height: auto;
        }
    
        &__media-item-wrapper{
            padding: 20px 0;
        }
    
        &__media-item{
            max-height: calc(var(--app-height) - 200px);
            width: auto;
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }


}