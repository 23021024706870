@import "../../styles/breakpoints";

.footer{

    &__nav-wrapper{
        position: fixed;
        bottom: 0;
        z-index: 3;
        font-size: 8px;
        line-height: 8px;
        margin: 10px;
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
            a{
                text-decoration: underline;
            }
        }
    }

    &__nav-item{
        margin-right: 20px;
    }
}

@include mobile{
    .footer{

        &__nav-wrapper{
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: calc(100% - 20px);
            &.left{
                left: 0;
                bottom: 20px;
            }
            &.right{
                right: 0;
            }
        }
    
        &__nav-item{
            margin-right: 20px;
        }
    }

}