@import"../styles/helpers";
@import"../styles/breakpoints";

.terms{

    &__outer-wrapper{
        position: fixed;
        width: 100%;
        height: var(--app-height);
        background-color: var(--grey-trans);
        z-index: 2;
        top: 0;
        left: 0;
        overflow-y: auto;
        cursor: default;
        p{
            margin-bottom: 20px;
        }
        strong{
            font-weight: bold;
        }
    }

    &__inner-wrapper{
        width: 40%;
        margin: 0 20px;
        padding: 100px 0;
        min-height: calc(var(--app-height) - 200px);
    }

    &__back-button{
        position: fixed;
        top: 0;
        left: 0;
        padding: 5px 10px;
        margin: 15px 20px;
        border: 0.5px solid transparent;
        transition: all 0.2s ease-in-out;
        @include hover{
            border: 0.5px solid #000;
        }
    }
}

@include mobile{

    .terms{
        &__outer-wrapper{
            width: calc(100% - 40px);
            padding: 100px 20px;
            height: calc(var(--app-height) - 200px);
            display: block;
            overflow-y: auto;
        }
        &__back-button{
            padding: 5px 10px;
            margin: 15px 5px;
        }
        &__inner-wrapper{
            width: 100%;
            margin: 0;
            padding: 0;
            min-height: calc(var(--app-height) - 200px);
        }
    }
}