@import "../styles/helpers";
@import "../styles/breakpoints";

.shop {
  &__outer-wrapper {
    position: fixed;
    width: 100%;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--grey-trans);
    z-index: 2;
    top: 0;
    left: 0;
    cursor: default;
  }

  &__inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  &__item-wrapper {
    width: 15vw;
    height: 15vw;
    margin: 0 20px;
    border: 0.5px solid transparent;
    transition: all 0.2s ease-in-out;
    text-align: center;
    overflow-y: visible;
    position: relative;
    @include hover {
      .shop__product-title {
        opacity: 1;
      }
      border: 0.5px solid #000;
    }
  }

  &__product-title {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 10px 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }

  &__product-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__back-button {
    position: fixed;
    top: 0;
    left: 0;
    padding: 5px 10px;
    margin: 15px 20px;
    border: 0.5px solid transparent;
    transition: all 0.2s ease-in-out;
    @include hover {
      border: 0.5px solid #000;
    }
  }
}

@include mobile {
  .shop {
    &__outer-wrapper {
      width: calc(100% - 40px);
      padding: 100px 20px;
      height: calc(var(--app-height) - 200px);
      display: block;
      overflow-y: auto;
    }
    &__inner-wrapper {
      display: block;
    }
    &__item-wrapper {
        @include hover {
          border: 0.5px solid transparent;
        }
      }
    &__product-title {
      position: static;
      opacity: 1;
    }
    &__back-button {
      padding: 5px 10px;
      margin: 15px 5px;
    }
  }
}
