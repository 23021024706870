
*::selection {
  background: var(--pink);
  color: #000;
}
*::-moz-selection {
	background: var(--pink);
	color: #000;
}

*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background-color: var(--grey)
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--grey);
  overscroll-behavior-y: none;
  overflow: hidden;
}

button {
	background: transparent;
	border: none;
	outline: none;
	box-shadow: none;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-transform: inherit;
	border: 0;
	margin: 0;
	padding: 0;
	border-radius: 0;
	color: inherit;
	cursor: pointer;
	text-decoration: none;
  }