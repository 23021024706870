@import "../styles/helpers";
@import "../styles/breakpoints";

.nav {
  &__add-jewel-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 20px 35%;
    width: 30%;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__add-jewel-title{
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  &__add-jewel-button-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }

  &__add-jewel-button{
    width: calc(20% - 11px);
    border: 0.5px solid transparent;
    transition: all 0.2s ease-in-out;
    padding-top: calc(20% - 11px);
    position: relative;
    @include hover{
      border: 0.5px solid #000;
    }
  }

  &__jewel-image{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 80%;
    height: 80%;
    margin: 10%;
    pointer-events: none;
  }

  &__misc-wrapper {
    margin: 20px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    button {
      width: 100%;
      display: block;
      margin: 5px 0;
      padding: 5px 20px;
      border: 0.5px solid #000;
    }
  }

  &__position-jewel-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 20px 30%;
    width: 40%;
    z-index: 10;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__position-jewels-controls{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__range-wrapper {
    width: calc(33.33% - 10px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__range {
    background-color: transparent;
    border-radius: 10px;
    width: 100%;
    display: block;
  }

  &__position-button {
    display: block;
    margin: 5px;
    width: 100%;
    padding: 5px 20px;
    border: 0.5px solid #000;
    border-radius: 30px;
    &.clear{
      width: auto;
      margin: 5px auto;
    }
  }
  &__preview-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__generic-button{
    display: inline-block;
    margin: 5px ;
    padding: 5px 20px;
    border: 0.5px solid #000;
    border-radius: 30px;
  }
}
input[type="range"] {
  width: 100%;
  margin: 10px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
  box-shadow: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0.5px solid var(--foreground-color);
  border-radius: 30px;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: none;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -3.5px;
  width: 16px;
  height: 15px;
  background: var(--foreground-color);
  border: 0.5px solid var(--foreground-color);
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--background-color);
}
input[type="range"]::-moz-range-track {
  background: var(--background-color);
  border: 0.5px solid var(--foreground-color);
  width: 100%;
  height: 10px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 15px;
  background: var(--foreground-color);
  border: 0.5px solid var(--foreground-color);
  border-radius: 3px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 0.5px 0;
  color: transparent;
  width: 100%;
  height: 10px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: var(--background-color);
  border: 0.5px solid var(--foreground-color);
}
input[type="range"]::-ms-fill-upper {
  background: var(--background-color);
  border: 0.5px solid var(--foreground-color);
}
input[type="range"]::-ms-thumb {
  width: 16px;
  height: 15px;
  background: var(--foreground-color);
  border: 0.5px solid var(--foreground-color);
  border-radius: 50%;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--background-color);
}
input[type="range"]:focus::-ms-fill-upper {
  background: var(--background-color);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

@include mobile {

  .nav{
    &__add-jewel-wrapper {
      margin: 20px 20px 60px 20px;
      width: calc(100% - 40px);
    }
    &__position-jewel-wrapper {
      margin: 20px 20px 60px 20px;
      width: calc(100% - 40px);
    }
  }
}