@import"../../styles/helpers";
@import"../../styles/breakpoints";

.header{

    &__logo-wrapper{
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        height: 40px;
        width: auto;
        z-index: 10;
    }

    &__logo{
        display: block;
        height: 100%;
        width: auto;
    }

    &__nav-wrapper{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 3;
        margin: 15px;
    }

    &__nav-item{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 2px;
        border: 0.5px solid transparent;
        transition: all 0.2s ease-in-out;
        @include hover{
            border: 0.5px solid #000;
        }
    }

    &__pre-launch{
        position: fixed;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        text-align: center;
        a{
            text-decoration: underline;
        }
        h1{
            margin-bottom: 10px;
        }
    }
    &__pre-launch-about{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 3;
        margin: 15px;
    }
}

@include mobile{
    .header{

        &__logo-wrapper{
            height: 20px;
        }

        &__nav-wrapper{
            position: fixed;
            width: calc(100vw - 30px);
            display: flex;
            flex-direction: row;
            justify-content: center;
            top: 30px;
            right: auto;
        }

        &__pre-launch{
            width: calc(100% - 40px);
            top: 60px;
            margin: 0 20px;
            left: 0;
            transform: none;
        }
    }
}