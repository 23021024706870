@import"../../styles/animations";

.loading{

    &__outer-wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 9000;
    }

    &__inner-wrapper{
        width: 100px;
        display: block;
        margin: 0 auto;
    }

    &__logo{
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    &__text{
        width: 100%;
        text-align: center;
        animation: pulse 1s linear infinite;
    }

}