.scene__wrapper{
    width: 100%;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    overscroll-behavior-y: none;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}