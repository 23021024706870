@font-face {
  font-family: "Diatype Extended";
  src: url("../assets/fonts/Diatype.eot");
  src: url("../assets/fonts/Diatype.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Diatype.woff") format("woff"),
    url("../assets/fonts/Diatype.ttf") format("truetype"),
    url("../assets/fonts/Diatype.svg#Diatype")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "Diatype Extended", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-color);
  text-transform: uppercase;
}

h1{
  font-size: 12px;
  line-height: 14px;
}

a{
  color: inherit;
  text-decoration: none;
}