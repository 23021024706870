@import"../styles/helpers";
@import"../styles/breakpoints";

.error{

    &__outer-wrapper{
        position: fixed;
        width: 100%;
        height: var(--app-height);
        background-color: var(--grey-trans);
        z-index: 2;
        top: 0;
        left: 0;
        overflow-y: auto;
        cursor: default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &__back-button{
        position: fixed;
        top: 0;
        left: 0;
        padding: 5px 10px;
        margin: 15px 20px;
        border: 0.5px solid transparent;
        transition: all 0.2s ease-in-out;
        @include hover{
            border: 0.5px solid #000;
        }
    }
}

@include mobile {
    .error{
        &__back-button{
            padding: 5px 10px;
            margin: 15px 5px;
        } 
    }
}