@import "../../styles/breakpoints";

.buy-button__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  border-bottom: 0.5px solid #000;

  .shopify-buy__btn {
    padding: 5px 20px;
    border: 0.5px solid #000;
    border-radius: 50px;
    margin: 0;
  }
}


.visuallyhidden {
  display: none;
}

.shopify-buy__select-icon{
  display: none;
}
