@import"../../styles/animations";

.preloader{

    &__outer-wrapper{
        width: 100%;
        height: var(--app-height);
        background-color: var(--grey);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        transition: opacity 0.8s ease-in-out;
        z-index: 10000;
        &.disabled{
            opacity: 0;
            pointer-events: none;
        }
    }

    &__inner-wrapper{
        width: 100px;
        display: block;
        margin: 0 auto;
    }

    &__logo{
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    &__loadbar-outer{
        width: 100%;
        height: 5px;
        border: 0.5px solid #000;
        border-radius: 30px;
        padding: 1px;
        margin-bottom: 5px;
    }

    &__loadbar-inner{
        height: 100%;
        background-color: #000;
        width: 0;
        border-radius: 30px;
    }

    &__text{
        width: 100%;
        text-align: center;
        animation: pulse 1s linear infinite;
    }

}